import Vue from 'vue'
import Vuex from 'vuex'
import { GetLeftMenu } from "../request/api"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //菜单列表信息
    menuinfo: [],
    userInfo:{},
    username: "",
    userphone:""
  },
  mutations: {
    // 用户信息
    setUserInfo(state, info)  {
      state.userInfo = info
    },
    //更新菜单列表信息
    setmenuinfo(state, info) {
      state.menuinfo = info
    },
    setusername(state, payload) {
      state.username = payload
    },
    setuserphone(state, payload) {
      state.userphone = payload
    }
  },
  actions: {
    // 解决刷新后菜单数据丢失

    async asyncsetmenuinfo({ commit }, payload) {
      commit("setusername", sessionStorage.getItem("user_name"))

      commit("setUserInfo", JSON.parse(sessionStorage.getItem("userInfo")))

      console.log('sessionStorage.getItem("userInfo")',sessionStorage.getItem("userInfo"));
      
      // console.log('sessionStorage.getItem("user_name")', sessionStorage.getItem("user_name"));
      

      // const MenuList = [
      //   {
      //     id: 7,
      //     name: "setting",
      //     pid: 0,
      //     remark: "设置",
      //   },
      //   {
      //     id: 8,
      //     name: "index",
      //     pid: 0,
      //     remark: "智慧环卫平台",
      //   },
      //   {
      //     id: 9,
      //     name: "streetList",
      //     pid: 0,
      //     remark: "街道管理",
      //   },
      //   {
      //     id: 10,
      //     name: "log",
      //     pid: 0,
      //     remark: "通讯日志",
      //   },
      // ];
      

      // // let res = await GetLeftMenu()

      // // let info = res.data.info.filter((item) => {
      // //   return item.id > 2
      // // })
      // commit("setmenuinfo",MenuList)


    }

  },
  modules: {
  }
})
